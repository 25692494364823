import React, { CSSProperties } from "react";
import { engineerColors, inimmedAxios } from "../../InimmedHelpers";
import { Employee } from "../../Interfaces";
import AddButton from "../Utility/AddButton";
import DropdownButton from "../Utility/DropdownButton";


export function checkIfEngineerIsFiltered(engineer: engineer_name | undefined, engineers_list: string[]) {
    for (const filtered_engineer of engineers_list) {
        if (filtered_engineer === engineer)
            return true;
    }
    return false;
}

export type engineer_name = 'Israel' | 'Juan Pablo' | 'José' | 'Enrique' | 'Dagoberto de Jesus' | 'Erick Jair' | 'Erik Leonel';

export interface CalendarEvent {
    title: string;
    client: string;
    location: string;
    type: string;
    section_to_link: number;
    itemID?: number;
    event_start: Date;
    event_end: Date;
    event_status: 'finished' | 'pending' | 'urgent' | 'in progress';
    engineer?: engineer_name;
    auxiliary_engineers: engineer_name[];
}

interface CalendarDayProps {
    changeSectionFunction: (setion: number, IDToQueue?: number | null, subsection?: string | null) => void,
    createWorkOrderFunction: (date: Date) => void,
    setActiveDay: (date: Date | null) => void,
    date: Date,
    activeDay: Date | null,
    active: boolean,
    today?: boolean,
    events?: CalendarEvent[]
    filtering: engineer_name[]
    user: Employee
}

class DesktopCalendarDay extends React.Component<CalendarDayProps, {}> {
    generateTimeSlots() {
        let event_divs: JSX.Element[] = [];
        let event_divs_counter = 0;

        if (this.props.events?.length) {
            for (const event of this.props.events) {
                const event_start_datetime = new Date(event.event_start);
                const event_end_datetime = new Date(event.event_end);
                let engineer_color: string = '';
                switch (event.engineer) {
                    case 'Israel':
                        engineer_color = engineerColors.Israel;
                        break;
                    // case 'Gustavo':
                    //     engineer_color = '#56A3A6';
                    //     break;
                    // case 'Cristian Josué':
                    //     engineer_color = 'purple';
                    //     break;
                    case 'Juan Pablo':
                        engineer_color = engineerColors.JuanPablo;
                        break;
                    case 'José':
                        engineer_color = engineerColors.Jose;
                        break;
                    case 'Enrique':
                        engineer_color = engineerColors.EnriquePalmeros;
                        break;
                    case 'Dagoberto de Jesus':
                        engineer_color = engineerColors.DagobertoGuevara;
                        break;
                    case 'Erick Jair':
                        engineer_color = engineerColors.ErickJair;
                        break;
                    case 'Erik Leonel':
                        engineer_color = engineerColors.ErikLeonel;
                        break;
                }
                let event_color = '';
                switch (event.event_status) {
                    case 'finished':
                        event_color = 'var(--secondary-dark-color)';
                        break;
                    case 'pending':
                        event_color = 'rgb(255, 222, 74)';
                        break;
                    case 'urgent':
                        event_color = '#ee3636';
                        break;
                    case 'in progress':
                        event_color = 'var(--secondary-color)';
                        break;
                }
                // if (this.props.date.getFullYear() >= event_start_datetime.getFullYear() && this.props.date.getMonth() >= event_start_datetime.getMonth() && this.props.date.getDate() >= event_start_datetime.getDate()
                // &&  this.props.date.getFullYear() <= event_end_datetime.getFullYear() && this.props.date.getMonth() <= event_end_datetime.getMonth() && this.props.date.getDate() <= event_end_datetime.getDate()
                // && checkIfEngineerIsFiltered(event.engineer, this.props.filtering)) {
                if (this.props.date.getFullYear() >= event_start_datetime.getFullYear() && this.props.date.getMonth() >= event_start_datetime.getMonth() && this.props.date.getDate() >= event_start_datetime.getDate()
                &&  this.props.date.getFullYear() <= event_end_datetime.getFullYear() && this.props.date.getMonth() <= event_end_datetime.getMonth() && this.props.date.getDate() <= event_end_datetime.getDate()
                &&  checkIfEngineerIsFiltered(event.engineer, this.props.filtering)) {
                    event_divs.push(
                        <p key={ event_divs_counter } onClick={() => this.props.changeSectionFunction(event.section_to_link, event.itemID)} className='float-from-down calendar-event-week-p' style={{ borderLeft: 'solid 5px ' + event_color, borderRight: 'solid 5px ' + engineer_color}}>{ event.title }</p>
                    );
                }
                event_divs_counter++;
            }
        return (event_divs);
        }
    }

    render() {
        let date_number_style: CSSProperties = {
            borderRadius: '13px',
            textAlign: 'center',
            width: '25px',
            height: '25px',
            margin: '5px',
            border: 'solid 1px transparent',
            position: 'sticky',
            top: '5px',
            userSelect: 'none',
            MozUserSelect: 'none',
            msUserSelect: 'none',
            WebkitUserSelect: 'none',
            cursor: 'pointer'
        }
        if (this.props.active) {
            if (this.props.today) {
                date_number_style.backgroundColor = 'var(--primary-color)';
                date_number_style.color = 'var(--on-primary-color)';
            } else {
                date_number_style.backgroundColor = 'var(--on-primary-color)';
            }
        } else {
            if (this.props.today) {
                date_number_style.backgroundColor = 'var(--on-primary-color)';
            } else {
                date_number_style.color = 'var(--on-primary-color)';
                date_number_style.backgroundColor = 'var(--primary-color)';
            }
        }
        return (
            <div style={{
                backgroundColor: this.props.active ? 'var(--on-primary-color)' : 'var(--primary-color)',
                overflow: 'visible'
                }}>
                { this.props.user.position === '1'  || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ?
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto', overflow: 'visible' }}>
                    <div style={{ position: 'relative', backgroundColor: 'var(--on-primary-color)', display: 'inline-block', overflow: 'visible' }}>
                        <div className='float-from-down' style={{ display: this.props.activeDay?.getTime() === this.props.date.getTime() ? 'flex' : 'none', flexDirection: 'column', gap: '0.5em', position: 'absolute', zIndex: '10', backgroundColor: 'var(--on-primary-color)', padding: '0', boxShadow: '0 0 15px 2px rgba(0, 0, 0, 0.1)', borderRadius: '10px', transform: 'translateY(-25%)' }}>
                            <img onClick={() => this.props.setActiveDay(null) } style={{ margin: '10px 0 0 10px', cursor: 'pointer' }} src='icons/close.svg' width='15px' alt='cerrar' />
                            <div style={{ display: 'flex', flexDirection: 'column', padding: '0 1em 1em 1em' }}>
                                <DropdownButton onClick={() => this.props.createWorkOrderFunction(new Date())}>Nueva Orden de Trabajo</DropdownButton>
                            </div>
                        </div>
                    </div>
                </div> : null }
                <div className='calendar-day-div'>
                    <div style={{ display: 'flex', flexDirection: 'column', overflow: 'visible' }}>
                        <p onClick={() => this.props.setActiveDay(this.props.date)} style={date_number_style}>{ this.props.date.getDate() }</p>
                    </div>
                    <div style={{ height: 'fit-content', width: '100%', display: 'flex', flexWrap: 'wrap', padding: '5px', gap: '5px', overflow: 'visible', justifyContent: 'center' }}>
                        { this.generateTimeSlots() }
                    </div>
                </div>
            </div>
        );
    }
}

interface CalendarProps {
    changeSectionFunction: (section: number, IDToQueue?: number | null, subsection?: string | null) => void,
    createWorkOrderFunction: (date: Date) => void,
    setActiveDay: (active_day: Date | null) => void,
    activeDay: Date | null,
    user: Employee
}

interface CalendarState {
    today: Date,
    current_month: number,
    current_year: number,
    events_in_month: CalendarEvent[],
    filtering: engineer_name[]
}

export class MonthCalendar extends React.Component<CalendarProps, CalendarState> {

    constructor(props: CalendarProps) {
        super(props);
        let today = new Date(Date.now());
        this.state = {
            today: today,
            current_month: today.getMonth(),
            current_year: today.getFullYear(),
            events_in_month: [],
            filtering: ['Israel', 'Juan Pablo', 'José', 'Enrique', 'Dagoberto de Jesus', 'Erick Jair', 'Erik Leonel'],
        }

        this.previousMonth = this.previousMonth.bind(this);
        this.nextMonth = this.nextMonth.bind(this);
        this.previousYear = this.previousYear.bind(this);
        this.nextYear = this.nextYear.bind(this);
        this.addEngineerToFilter = this.addEngineerToFilter.bind(this);
    }
    
    componentDidMount() {
        inimmedAxios.get('api/get_month_events', {
            params: {
                month: this.state.current_month + 1,
                year: this.state.current_year,
                long_title: false,
                user: this.props.user.id
            }
        })
        .then(response => {
            this.setState({ events_in_month: response.data });
        })
        .catch(reason => alert(reason));
    }

    parseMonth() {
        switch (this.state.current_month) {
            case 0:
                return 'Enero';
            case 1:
                return 'Febrero';
            case 2:
                return 'Marzo';
            case 3:
                return 'Abril';
            case 4:
                return 'Mayo';
            case 5:
                return 'Junio';
            case 6:
                return 'Julio';
            case 7:
                return 'Agosto';
            case 8:
                return 'Septiembre';
            case 9:
                return 'Octubre';
            case 10:
                return 'Noviembre';
            case 11:
                return 'Diciembre';
        }
    }

    getMonthDays() {
        const current_month_days = new Date(this.state.current_year, this.state.current_month + 1, 0).getDate()
        return current_month_days;
    }

    generateDayDivs() {
        let divs_to_generate: JSX.Element[] = [];

        // Get the datetime of first day of the current month being displayed.
        const first_of_current_month = new Date(this.state.current_year, this.state.current_month, 1);
        let last_of_current_month: Date;
        if (this.state.current_month === 11) {
            last_of_current_month = new Date(this.state.current_year + 1, 0, 0);
        } else {
            last_of_current_month = new Date(this.state.current_year, this.state.current_month + 1, 0);
        }

        // Iterate over the inactive days before the start of the current month.
        for (let i = first_of_current_month.getDay(); i > 0; i--) {

            // Generate a datetime for the current day iterated.
            const current_day_iterated = new Date(first_of_current_month.getFullYear(), first_of_current_month.getMonth(), first_of_current_month.getDate() - i);
            
            const events_in_day: CalendarEvent[] = [];
            for (const event of this.state.events_in_month) {
                const event_start = new Date(event.event_start);
                const event_end = new Date(event.event_end);
                if ((event_start.getFullYear() <= current_day_iterated.getFullYear() && event_start.getMonth() <= current_day_iterated.getMonth() && event_start.getDate() <= current_day_iterated.getDate()) && (event_end.getFullYear() >= current_day_iterated.getFullYear() && event_end.getMonth() >= current_day_iterated.getMonth() && event_end.getDate() >= current_day_iterated.getDate())) {
                    events_in_day.push(event);
                }
            }

            // Check if one of these days is today.
            if (current_day_iterated.getFullYear() === this.state.today.getFullYear() && current_day_iterated.getMonth() === this.state.today.getMonth() && current_day_iterated.getDate() === this.state.today.getDate()) {
                divs_to_generate.push(<DesktopCalendarDay user={this.props.user} filtering={ this.state.filtering } setActiveDay={this.props.setActiveDay} createWorkOrderFunction={ this.props.createWorkOrderFunction } changeSectionFunction={this.props.changeSectionFunction} date={current_day_iterated} activeDay={this.props.activeDay} active={false} today events={events_in_day} />);
            } else {
                divs_to_generate.push(<DesktopCalendarDay user={this.props.user} filtering={ this.state.filtering } setActiveDay={this.props.setActiveDay} createWorkOrderFunction={ this.props.createWorkOrderFunction } changeSectionFunction={this.props.changeSectionFunction} date={current_day_iterated} activeDay={this.props.activeDay} active={false} events={events_in_day} />);
            }

        }
        for (let i = 0; i < last_of_current_month.getDate(); i++) {
            const current_day_iterated = new Date(first_of_current_month.getFullYear(), first_of_current_month.getMonth(), i + 1);
            const events_in_day: CalendarEvent[] = [];
            for (const event of this.state.events_in_month) {
                const event_start = new Date(event.event_start);
                const event_end = new Date(event.event_end);
                if ((event_start.getFullYear() <= this.state.current_year && event_start.getMonth() <= this.state.current_month && event_start.getDate() <= current_day_iterated.getDate()) && (event_end.getFullYear() >= this.state.current_year && event_end.getMonth() >= this.state.current_month && event_end.getDate() >= current_day_iterated.getDate())) {
                    events_in_day.push(event);
                }
            }
            if (current_day_iterated.getFullYear() === this.state.today.getFullYear() && current_day_iterated.getMonth() === this.state.today.getMonth() && current_day_iterated.getDate() === this.state.today.getDate()) {
                divs_to_generate.push(<DesktopCalendarDay user={this.props.user} filtering={ this.state.filtering } setActiveDay={this.props.setActiveDay} createWorkOrderFunction={ this.props.createWorkOrderFunction } changeSectionFunction={this.props.changeSectionFunction} date={current_day_iterated} activeDay={this.props.activeDay} active={true} today events={events_in_day} />);
                continue;
            }
            divs_to_generate.push(<DesktopCalendarDay user={this.props.user} filtering={ this.state.filtering } setActiveDay={this.props.setActiveDay} createWorkOrderFunction={ this.props.createWorkOrderFunction } changeSectionFunction={this.props.changeSectionFunction} date={current_day_iterated} activeDay={this.props.activeDay} active={true} events={events_in_day} />);
        }
        let count = last_of_current_month.getDate() + first_of_current_month.getDay();
        const max_count = first_of_current_month.getDay() + last_of_current_month.getDate() > 35 ? 42 : 35;
        for (let i = last_of_current_month.getDate() + 1; count < max_count; i++) {
            const current_day_iterated = new Date(last_of_current_month.getFullYear(), last_of_current_month.getMonth(), i);
            const events_in_day: CalendarEvent[] = [];
            for (const event of this.state.events_in_month) {
                const event_start = new Date(event.event_start);
                const event_end = new Date(event.event_end);
                if ((event_start.getFullYear() <= current_day_iterated.getFullYear() && event_start.getMonth() <= current_day_iterated.getMonth() && event_start.getDate() <= current_day_iterated.getDate()) && (event_end.getFullYear() >= current_day_iterated.getFullYear() && event_end.getMonth() >= current_day_iterated.getMonth() && event_end.getDate() >= current_day_iterated.getDate())) {
                    events_in_day.push(event);
                }
            }
            if (current_day_iterated.getFullYear() === this.state.today.getFullYear() && current_day_iterated.getMonth() === this.state.today.getMonth() && current_day_iterated.getDate() === this.state.today.getDate()) {
                divs_to_generate.push(<DesktopCalendarDay user={this.props.user} filtering={ this.state.filtering } setActiveDay={this.props.setActiveDay} createWorkOrderFunction={ this.props.createWorkOrderFunction } changeSectionFunction={this.props.changeSectionFunction} date={current_day_iterated} activeDay={this.props.activeDay} active={false} today events={events_in_day} />);
                continue;
            } else {
                divs_to_generate.push(<DesktopCalendarDay user={this.props.user} filtering={ this.state.filtering } setActiveDay={this.props.setActiveDay} createWorkOrderFunction={ this.props.createWorkOrderFunction } changeSectionFunction={this.props.changeSectionFunction} date={current_day_iterated} activeDay={this.props.activeDay} active={false} events={events_in_day} />);
            }
            count++;
        }
        return divs_to_generate;
    }

    nextMonth(event: React.MouseEvent<HTMLButtonElement>) {
        const newMonth = this.state.current_month === 11 ? 0 : this.state.current_month + 1;
        const newYear = newMonth === 0 ? this.state.current_year + 1 : this.state.current_year;
        this.setState({ current_month: newMonth, current_year: newYear }, () => {
            inimmedAxios.get('api/get_month_events', {
                params: {
                    month: this.state.current_month + 1,
                    year: this.state.current_year,
                    long_title: false,
                    user: this.props.user.id
                }
            })
            .then(response => {
                this.setState({ events_in_month: response.data });
            })
            .catch(reason => alert(reason));
        });
    }

    previousMonth(event: React.MouseEvent<HTMLButtonElement>) {
        const newMonth = this.state.current_month - 1 > 0 ? this.state.current_month - 1 : 11;
        const newYear = newMonth === 11 ? this.state.current_year - 1 : this.state.current_year;
        this.setState({ current_month: newMonth, current_year: newYear }, () => {
            inimmedAxios.get('api/get_month_events', {
                params: {
                    month: this.state.current_month + 1,
                    year: this.state.current_year,
                    long_title: false,
                    user: this.props.user.id
                }
            })
            .then(response => {
                this.setState({ events_in_month: response.data });
            })
            .catch(reason => alert(reason));
        });
    }

    previousYear(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({ current_year: this.state.current_year - 1 }, () => {
            inimmedAxios.get('api/get_month_events', {
                params: {
                    month: this.state.current_month + 1,
                    year: this.state.current_year,
                    long_title: false,
                    user: this.props.user.id
                }
            })
            .then(response => {
                this.setState({ events_in_month: response.data });
            })
            .catch(reason => alert(reason));
        });
    }

    nextYear(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({ current_year: this.state.current_year + 1}, () => {
            inimmedAxios.get('api/get_month_events', {
                params: {
                    month: this.state.current_month + 1,
                    year: this.state.current_year,
                    long_title: false,
                    user: this.props.user.id
                }
            })
            .then(response => {
                this.setState({ events_in_month: response.data });
            })
            .catch(reason => alert(reason));
        });
    }

    addEngineerToFilter(event: React.MouseEvent<HTMLParagraphElement>) {
        const engineerNameP = event.target as HTMLParagraphElement;
        for (let i = 0; i < this.state.filtering.length; i++) {
            if (this.state.filtering[i] === engineerNameP.innerHTML) {
                const newFiltering = this.state.filtering;
                newFiltering.splice(i, 1);
                this.setState({ filtering: newFiltering })
                return;
            }
        }
        this.setState({ filtering: [...this.state.filtering, engineerNameP.innerHTML as engineer_name] });
    }

    render() {
        const first_of_current_month = new Date(this.state.current_year, this.state.current_month, 1);
        let last_of_current_month: Date;
        if (this.state.current_month === 11) {
            last_of_current_month = new Date(this.state.current_year + 1, 0, 0);
        } else {
            last_of_current_month = new Date(this.state.current_year, this.state.current_month + 1, 0);
        }
        let rows = 5;
        if (first_of_current_month.getDay() + last_of_current_month.getDate() > 35) {
            rows = 6;
        }
        return (
            <div className='float-from-down' style={{backgroundColor: 'var(--on-primary-color)', width: '90%', margin: 'auto' }}>
                <div style={{ display: 'flex', position: 'relative' }}>
                    { this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ?
                    <div style={{ left: '0', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(4, 1fr)', fontWeight: 'bold', columnGap: '1em' }}>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('Israel', this.state.filtering) ? engineerColors.Israel : '', marginRight: '0.2em', border: `solid 2px ${engineerColors.Israel}` }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>Israel</p>
                        </div>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('Juan Pablo', this.state.filtering) ? engineerColors.JuanPablo : '', marginRight: '0.2em', border: `solid 2px ${engineerColors.JuanPablo}` }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>Juan Pablo</p>
                        </div>
                        {/* <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('Cristian Josué', this.state.filtering) ? 'purple' : '', marginRight: '0.2em', border: 'solid 2px purple' }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>Cristian Josué</p>
                        </div>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('Gustavo', this.state.filtering) ? '#56A3A6' : '', marginRight: '0.2em', border: 'solid 2px #56A3A6' }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>Gustavo</p>
                        </div> */}
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('José', this.state.filtering) ? engineerColors.Jose : '', marginRight: '0.2em', border: `solid 2px ${engineerColors.Jose}` }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>José</p>
                        </div>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('Enrique', this.state.filtering) ? engineerColors.EnriquePalmeros : '', marginRight: '0.2em', border: `solid 2px ${engineerColors.EnriquePalmeros}` }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>Enrique</p>
                        </div>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('Dagoberto de Jesus', this.state.filtering) ? engineerColors.DagobertoGuevara : '', marginRight: '0.2em', border: `solid 2px ${engineerColors.DagobertoGuevara}` }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>Dagoberto de Jesus</p>
                        </div>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('Erick Jair', this.state.filtering) ? engineerColors.ErickJair : '', marginRight: '0.2em', border: `solid 2px ${engineerColors.ErickJair}` }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>Erick Jair</p>
                        </div>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('Erik Leonel', this.state.filtering) ? engineerColors.ErikLeonel : '', marginRight: '0.2em', border: `solid 2px ${engineerColors.ErikLeonel}` }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>Erik Leonel</p>
                        </div>
                    </div> : null }
                    <div style={{ display: 'flex',gap: '1em', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <button style={{ height: '20px', fontSize: '1.5em', border: 'none', background: 'none', margin: '0', padding: '0' }} onClick={ this.previousMonth }><img alt='flecha-abajo' src='icons/down.svg' height={20} style={{ transform: 'rotate(180deg)' }} /></button>
                            <h1 style={{margin: '0'}}>{ this.parseMonth() }</h1>
                            <button style={{ height: '20px', fontSize: '1.5em', border: 'none', background: 'none', margin: '0', padding: '0' }} onClick={ this.nextMonth}><img alt='flecha-abajo' src='icons/down.svg' height={20} /></button>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <button style={{ height: '20px', fontSize: '1.5em', border: 'none', background: 'none', margin: '0', padding: '0' }} onClick={ this.previousYear }><img alt='flecha-abajo' src='icons/down.svg' height={20} style={{ transform: 'rotate(180deg)' }} /></button>
                            <h1 style={{margin: '0'}}>{ this.state.current_year }</h1>
                            <button style={{ height: '20px', fontSize: '1.5em', border: 'none', background: 'none', margin: '0', padding: '0' }} onClick={ this.nextYear }><img alt='flecha-abajo' src='icons/down.svg' height={20} /></button>
                        </div>
                    </div>
                </div>
                <div style={{display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)'}}>
                    <p style={{margin: '0', textAlign: 'center', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)', borderRadius: '10px 0 0 0'}}>Domingo</p>
                    <p style={{margin: '0', textAlign: 'center', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)'}}>Lunes</p>
                    <p style={{margin: '0', textAlign: 'center', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)'}}>Martes</p>
                    <p style={{margin: '0', textAlign: 'center', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)'}}>Miércoles</p>
                    <p style={{margin: '0', textAlign: 'center', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)'}}>Jueves</p>
                    <p style={{margin: '0', textAlign: 'center', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)'}}>Viernes</p>
                    <p style={{margin: '0', textAlign: 'center', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)', borderRadius: '0 10px 0 0'}}>Sábado</p>
                </div>
                <div style={{display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gridTemplateRows: 'repeat(' + rows + ', 1fr)', gap: '1px', boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)', backgroundColor: 'var(--primary-color)'}}>
                    { this.generateDayDivs() }
                </div>
            </div>
        )
    }
}






interface WeekCalendarDayProps {
    changeSectionFunction: (section: number, IDToQueue?: number | null, subsection?: string | null) => void,
    createWorkOrderFunction: (date: Date) => void,
    setActiveDay: (date: Date | null) => void,
    date: Date,
    today: Date,
    activeDay: Date | null,
    events: CalendarEvent[],
    filtering: string[],
    user: Employee
}

export class WeekCalendarDay extends React.Component<WeekCalendarDayProps, {}> {

    generateTimeSlots() {
        let event_divs: JSX.Element[] = [];
        let event_div_counter = 0;

        for (const event of this.props.events) {
            const event_start_datetime = new Date(event.event_start);
            const event_end_datetime = new Date(event.event_end);
            let engineer_color: string = '';
            switch (event.engineer) {
                case 'Israel':
                    engineer_color = engineerColors.Israel;
                    break;
                // case 'Gustavo':
                //     engineer_color = engineerColors.Gustavo;
                //     break;
                // case 'Cristian Josué':
                //     engineer_color = engineerColors.JoseDaniel;
                //     break;
                case 'José':
                    engineer_color = engineerColors.Jose;
                    break;
                case 'Juan Pablo':
                    engineer_color = engineerColors.JuanPablo;
                    break;
                case 'Enrique':
                    engineer_color = engineerColors.EnriquePalmeros;
                    break;
                case 'Dagoberto de Jesus':
                    engineer_color = engineerColors.DagobertoGuevara;
                    break;
                case 'Erick Jair':
                    engineer_color = engineerColors.ErickJair;
                    break;
                case 'Erik Leonel':
                    engineer_color = engineerColors.ErikLeonel;
                    break;
            }
            let event_color = '';
            switch (event.event_status) {
                case 'finished':
                    event_color = 'var(--secondary-dark-color)';
                    break;
                case 'pending':
                    event_color = 'rgb(255, 222, 74)';
                    break;
                case 'urgent':
                    event_color = '#ee3636';
                    break;
                case 'in progress':
                    event_color = 'var(--secondary-color)';
                    break;
            }
            if (this.props.date.getFullYear() >= event_start_datetime.getFullYear() && this.props.date.getMonth() >= event_start_datetime.getMonth() && this.props.date.getDate() >= event_start_datetime.getDate()
            &&  this.props.date.getFullYear() <= event_end_datetime.getFullYear() && this.props.date.getMonth() <= event_end_datetime.getMonth() && this.props.date.getDate() <= event_end_datetime.getDate()
            && checkIfEngineerIsFiltered(event.engineer, this.props.filtering)) {
                event_divs.push(
                    <p key={ event_div_counter } onClick={() => this.props.changeSectionFunction(event.section_to_link, event.itemID)} className='float-from-down calendar-event-week-p' style={{ borderLeft: 'solid 5px ' + event_color, borderRight: 'solid 5px ' + engineer_color}}>{ event.title }</p>
                );
            }
            event_div_counter++;
        }
    return (event_divs);
    }

    render() {
        let today_date_background = '';
        let today_date_foreground = '';
        if (this.props.today.getFullYear() === this.props.date.getFullYear() && this.props.today.getMonth() === this.props.date.getMonth() && this.props.today.getDate() === this.props.date.getDate()) {
            today_date_background = 'var(--primary-color)';
            today_date_foreground = 'var(--on-primary-color)';
        }
        return (
            <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'var(--on-primary-color)', overflow: 'auto' }}>
                <div style={{ height: 'fit-content', width: '100%', display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center', overflow: 'visible', marginTop: '2em', marginBottom: '1em' }}>
                    { this.generateTimeSlots() }
                </div>
                { this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ?
                <div style={{ overflow: 'visible', position: 'relative' }}>
                    <AddButton onClick={() => this.props.setActiveDay(this.props.date)} />
                    <div style={{ zIndex: '5', position: 'fixed', backgroundColor: 'var(--on-primary-color)', display: 'inline-block', overflow: 'visible' }}>
                        <div className='float-from-down' style={{ display: this.props.activeDay?.getFullYear() === this.props.date.getFullYear() && this.props.activeDay.getMonth() === this.props.date.getMonth() && this.props.activeDay.getDate() === this.props.date.getDate()  ? 'flex' : 'none', flexDirection: 'column', gap: '0.5em', position: 'absolute', zIndex: '10', backgroundColor: 'var(--on-primary-color)', padding: '0', boxShadow: '0 0 15px 2px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
                            <img onClick={() => this.props.setActiveDay(null) } style={{ margin: '10px 0 0 10px', cursor: 'pointer' }} src='icons/close.svg' width='15px' alt='cerrar' />
                            <div style={{ display: 'flex', flexDirection: 'column', padding: '0 1em 1em 1em' }}>
                                <DropdownButton onClick={() => this.props.createWorkOrderFunction(new Date())}>Nueva Orden de Trabajo</DropdownButton>
                            </div>
                        </div>
                    </div>
                </div> : null }
                <div style={{ position: 'fixed', zIndex: '4', backgroundColor: today_date_background, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '1.5em', width: '1.5em', borderRadius: '50%', color: today_date_foreground }}>
                    <p>{ this.props.date.getDate() }</p>
                </div>
            </div>
        );
    }
}

interface WeekCalendarProps {
    changeSectionFunction: (section: number, IDToQueue?: number | null, subsection?: string | null) => void,
    createWorkOrderFunction: (date: Date) => void,
    setActiveDay: (date: Date | null) => void,
    activeDay: Date | null,
    user: Employee
}

interface WeekCalendarState {
    today: Date,
    current_week_start: Date,
    events_in_week: CalendarEvent[],
    filtering: engineer_name[]
}

export class WeekCalendar extends React.Component<WeekCalendarProps, WeekCalendarState> {
    constructor(props: WeekCalendarProps) {
        super(props);
        
        const today = new Date(Date.now());
        this.state = {
            today: today,
            current_week_start: this.calculateWeekStart(today),
            events_in_week: [],
            filtering: ['Israel', 'Juan Pablo', 'José', 'Enrique', 'Dagoberto de Jesus', 'Erick Jair', 'Erik Leonel'],
        }

        this.nextWeek = this.nextWeek.bind(this);
        this.previousWeek = this.previousWeek.bind(this);
        this.addEngineerToFilter = this.addEngineerToFilter.bind(this);
    }

    componentDidMount() {
        const week_start = this.calculateWeekStart(this.state.today);
        const week_end = this.calculateWeekEnd(this.state.today);
        inimmedAxios.get('api/get_week_events', {
            params: {
                start_year: week_start.getFullYear(),
                start_month: week_start.getMonth() + 1,
                start_date: week_start.getDate(),
                end_year: week_end.getFullYear(),
                end_month: week_end.getMonth() + 1,
                end_date: week_end.getDate(),
                long_title: true,
                user: this.props.user.id
            }
        })
        .then(response => {
            this.setState({ events_in_week: response.data });
        })
        .catch(reason => {
            alert(reason);
        })
    }

    parseMonth(month: number) {
        switch (month) {
            case 0:
                return 'Enero';
            case 1:
                return 'Febrero';
            case 2:
                return 'Marzo';
            case 3:
                return 'Abril';
            case 4:
                return 'Mayo';
            case 5:
                return 'Junio';
            case 6:
                return 'Julio';
            case 7:
                return 'Agosto';
            case 8:
                return 'Septiembre';
            case 9:
                return 'Octubre';
            case 10:
                return 'Noviembre';
            case 11:
                return 'Diciembre';
        }
    }

    generateTitle() {
        const current_week_end = this.calculateWeekEnd(this.state.current_week_start);
        if (this.state.current_week_start.getMonth() !== current_week_end.getMonth()) {
            return this.state.current_week_start.getDate() + ' de ' + this.parseMonth(this.state.current_week_start.getMonth()) + ' a ' + current_week_end.getDate() + ' de ' + this.parseMonth(current_week_end.getMonth());
        } else {
            return this.state.current_week_start.getDate() + ' a ' + current_week_end.getDate() + ' de ' + this.parseMonth(current_week_end.getMonth());
        }
    }

    generateDayDivs() {
        let day_divs: JSX.Element[] = [];
        let day_divs_counter = 0;

        for (let i = this.state.current_week_start.getDate(); i < this.state.current_week_start.getDate() + 7; i++) {
            let current_day_iterated = new Date(this.state.current_week_start.getFullYear(), this.state.current_week_start.getMonth(), i);
            let events_of_day: CalendarEvent[] = [];
            for (const event of this.state.events_in_week) {
                const event_start: Date = new Date(event.event_start);
                const event_end: Date = new Date(event.event_end);
                if (event_start.getFullYear() <= current_day_iterated.getFullYear() && event_start.getMonth() <= current_day_iterated.getMonth() && event_start.getDate() <= current_day_iterated.getDate()
                &&  event_end.getFullYear() >= current_day_iterated.getFullYear() && event_end.getMonth() >= current_day_iterated.getMonth() && event_end.getDate() >= current_day_iterated.getDate()) {
                    events_of_day.push(event);
                }
            }
            day_divs.push(
                <div key={ day_divs_counter } style={{display: 'flex', backgroundColor: 'var(--primary-color)', height: '40em', paddingLeft: '1px', paddingRight: '1px' }}>
                    <WeekCalendarDay user={this.props.user} filtering={ this.state.filtering } createWorkOrderFunction={ this.props.createWorkOrderFunction } activeDay={ this.props.activeDay } setActiveDay={ this.props.setActiveDay } changeSectionFunction={ this.props.changeSectionFunction }  date={current_day_iterated} events={events_of_day} today={this.state.today} />
                </div>
            );
            day_divs_counter++;
        }
        return day_divs;
    }

    calculateNextWeekStart() {
        return new Date(this.state.current_week_start.getFullYear(), this.state.current_week_start.getMonth(), this.state.current_week_start.getDate() + 7);
    }

    calculateWeekStart(date: Date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
    }

    calculateWeekEnd(date: Date) {
        const current_week_start = this.calculateWeekStart(date);
        const six_days_after_week_start = new Date(current_week_start.getFullYear(), current_week_start.getMonth(), current_week_start.getDate() + 6);
        return six_days_after_week_start;
    }

    calculatePreviousWeekStart() {
        return new Date(this.state.current_week_start.getFullYear(), this.state.current_week_start.getMonth(), this.state.current_week_start.getDate() - 7);
    }

    nextWeek(event: React.MouseEvent<HTMLButtonElement>) {
        const next_week_start = this.calculateNextWeekStart();
        this.setState({ current_week_start: next_week_start }, () => {
            const week_end = this.calculateWeekEnd(next_week_start);
            inimmedAxios.get('api/get_week_events', {
                params: {
                    start_year: this.state.current_week_start.getFullYear(),
                    start_month: this.state.current_week_start.getMonth() + 1,
                    start_date: this.state.current_week_start.getDate(),
                    end_year: week_end.getFullYear(),
                    end_month: week_end.getMonth() + 1,
                    end_date: week_end.getDate(),
                    long_title: true,
                    user: this.props.user.id
                }
            })
            .then(response => {
                this.setState({ events_in_week: response.data });
            })
            .catch(reason => alert(reason));
        });
    }

    previousWeek(event: React.MouseEvent<HTMLButtonElement>) {
        const previous_week_start = this.calculatePreviousWeekStart();
        this.setState({ current_week_start: previous_week_start }, () => {
            const week_end = this.calculateWeekEnd(previous_week_start);
            inimmedAxios.get('api/get_week_events', {
                params: {
                    start_year: this.state.current_week_start.getFullYear(),
                    start_month: this.state.current_week_start.getMonth() + 1,
                    start_date: this.state.current_week_start.getDate(),
                    end_year: week_end.getFullYear(),
                    end_month: week_end.getMonth() + 1,
                    end_date: week_end.getDate(),
                    long_title: true,
                    user: this.props.user.id
                }
            })
            .then(response => {
                this.setState({ events_in_week: response.data });
            })
            .catch(reason => alert(reason));
        });
    }

    addEngineerToFilter(event: React.MouseEvent<HTMLParagraphElement>) {
        const engineerNameP = event.target as HTMLParagraphElement;
        for (let i = 0; i < this.state.filtering.length; i++) {
            if (this.state.filtering[i] === engineerNameP.innerHTML) {
                const newFiltering = this.state.filtering;
                newFiltering.splice(i, 1);
                this.setState({ filtering: newFiltering })
                return;
            }
        }
        this.setState({ filtering: [...this.state.filtering, engineerNameP.innerHTML as engineer_name] });
    }

    render() {
        return (
            <div className='float-from-down' style={{ backgroundColor: 'var(--on-primary-color)', width: '90%', margin: 'auto' }}>
                <div style={{ display: 'flex', position: 'relative' }}>
                    { this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ?
                    <div style={{ left: '0', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(3, 1fr)', fontWeight: 'bold', columnGap: '1em' }}>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('Israel', this.state.filtering) ? engineerColors.Israel : '', marginRight: '0.2em', border: `solid 2px ${engineerColors.Israel}` }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>Israel</p>
                        </div>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('Juan Pablo', this.state.filtering) ? engineerColors.JuanPablo : '', marginRight: '0.2em', border: `solid 2px ${engineerColors.JuanPablo}` }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>Juan Pablo</p>
                        </div>
                        {/* <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('Cristian Josué', this.state.filtering) ? 'purple' : '', marginRight: '0.2em', border: 'solid 2px purple' }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>Cristian Josué</p>
                        </div> */}
                        {/* <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('Gustavo', this.state.filtering) ? '#56A3A6' : '', marginRight: '0.2em', border: 'solid 2px #56A3A6' }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>Gustavo</p>
                        </div> */}
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('José', this.state.filtering) ? engineerColors.Jose : '', marginRight: '0.2em', border: `solid 2px ${engineerColors.Jose}` }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>José</p>
                        </div>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('Enrique', this.state.filtering) ? engineerColors.EnriquePalmeros : '', marginRight: '0.2em', border: `solid 2px ${engineerColors.EnriquePalmeros}` }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>Enrique</p>
                        </div>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('Dagoberto de Jesus', this.state.filtering) ? engineerColors.DagobertoGuevara : '', marginRight: '0.2em', border: `solid 2px ${engineerColors.DagobertoGuevara}` }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>Dagoberto de Jesus</p>
                        </div>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('Erick Jair', this.state.filtering) ? engineerColors.ErickJair : '', marginRight: '0.2em', border: `solid 2px ${engineerColors.ErickJair}` }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>Erick Jair</p>
                        </div>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div style={{ width: '10px', height: '100%', backgroundColor: checkIfEngineerIsFiltered('Erik Leonel', this.state.filtering) ? engineerColors.ErikLeonel : '', marginRight: '0.2em', border: `solid 2px ${engineerColors.ErikLeonel}` }}></div><p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer' }}>Erik Leonel</p>
                        </div>
                    </div> : null }
                    <div style={{ display: 'flex', gap: '1em', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <button onClick={this.previousWeek} style={{ height: '20px', fontSize: '1.5em', border: 'none', background: 'none', margin: '0', padding: '0' }}><img alt='flecha-abajo' src='icons/down.svg' height={20} style={{ transform: 'rotate(180deg)' }} /></button>
                            <h1 style={{margin: '0'}}>{ this.generateTitle() }</h1>
                            <button onClick={this.nextWeek} style={{ height: '20px', fontSize: '1.5em', border: 'none', background: 'none', margin: '0', padding: '0' }}><img alt='flecha-abajo' src='icons/down.svg' height={20} /></button>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column'  }}>
                            <button style={{ height: '20px', fontSize: '1.5em', border: 'none', background: 'none', margin: '0', padding: '0' }}><img alt='flecha-abajo' src='icons/down.svg' height={20} style={{ transform: 'rotate(180deg)' }} /></button>
                            <h1 style={{margin: '0'}}>{ '2022' }</h1>
                            <button style={{ height: '20px', fontSize: '1.5em', border: 'none', background: 'none', margin: '0', padding: '0' }}><img alt='flecha-abajo' src='icons/down.svg' height={20} /></button>
                        </div>
                    </div>
                </div>
                <div style={{display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)'}}>
                    <p style={{margin: '0', textAlign: 'center', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)', borderRadius: '10px 0 0 0'}}>Domingo</p>
                    <p style={{margin: '0', textAlign: 'center', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)'}}>Lunes</p>
                    <p style={{margin: '0', textAlign: 'center', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)'}}>Martes</p>
                    <p style={{margin: '0', textAlign: 'center', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)'}}>Miércoles</p>
                    <p style={{margin: '0', textAlign: 'center', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)'}}>Jueves</p>
                    <p style={{margin: '0', textAlign: 'center', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)'}}>Viernes</p>
                    <p style={{margin: '0', textAlign: 'center', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)', borderRadius: '0 10px 0 0'}}>Sábado</p>
                </div>
                <div style={{display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)'}}>
                    { this.generateDayDivs() }
                </div>
            </div>
        )
    }
}
